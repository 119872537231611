.app-bar {
    &.MuiAppBar-root {
        width: 100%;
        background-color: #fff;
        height: 75px;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        transition: none;
        padding: 20px 0;
    }

    .cisp1-logo {
        height: 35px;
        width: auto;
    }
}